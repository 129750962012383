<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">考试查询</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl flexdc operationControlExamination"
          style="align-items: flex-start"
        >
          <div class="searchbox" style="display: flex">
            <div
              title="学员搜索"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span
                class="itemLabel"
                style="
                  min-width: 5rem;
                  text-align: right;
                  font-size: 14px;
                  padding: 0 0.5rem;
                "
                >学员搜索:</span
              >
              <el-select
                v-model="userId"
                placeholder="请选择"
                no-data-text="没有数据"
                remote
                size="small"
                clearable
                :remote-method="superUserSelect"
                @visible-change="clearUserSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      "
                      >学员姓名</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      "
                      >身份证号码</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 100px;
                      "
                      >学员电话</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaUserName"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员姓名"
                      clearable
                      style="width: 100px"
                    />
                    <el-input
                      v-model="seaUserIdcard"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="身份证号码"
                      clearable
                      style="margin-left: 50px; width: 140px"
                    />
                    <el-input
                      v-model="seaUserMobile"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员电话"
                      clearable
                      style="margin-left: 50px; width: 100px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="(item, index) in userList"
                  :key="index"
                  :label="item.userName"
                  :value="item.userId"
                >
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span
                    style="width: 140px; margin-left: 50px; font-size: 13px"
                    >{{ item.idcard }}</span
                  >
                  <span
                    style="width: 100px; margin-left: 50px; font-size: 13px"
                    >{{ item.mobile }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div
              title="班级搜索"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span
                class="itemLabel"
                style="
                  min-width: 5rem;
                  text-align: right;
                  font-size: 14px;
                  padding: 0 0.5rem;
                "
                >班级搜索:</span
              >
              <el-select
                v-model="projectId"
                placeholder="请选择"
                remote
                size="small"
                clearable
                :remote-method="superPorjectSelect"
                @visible-change="clearProjectSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 150px;
                      "
                      >班级编号</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 250px;
                      "
                      >班级名称</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaProjectCode"
                      v-on:input="superPorjectSelect"
                      type="text"
                      size="small"
                      placeholder="班级编号"
                      clearable
                      style="width: 150px"
                    />
                    <el-input
                      v-model="seaProjectName"
                      v-on:input="superPorjectSelect"
                      type="text"
                      size="small"
                      placeholder="请输入班级名称"
                      clearable
                      style="margin-left: 50px; width: 250px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="(item, index) in projectList"
                  :key="index"
                  :label="item.projectName"
                  :value="item.projectId"
                >
                  <span style="width: 150px; font-size: 13px">{{
                    item.projectCode
                  }}</span>
                  <span
                    style="width: 250px; margin-left: 50px; font-size: 13px"
                    >{{ item.projectName }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div title="试卷名称" class="searchboxItem ci-full">
              <span class="itemLabel">试卷名称:</span>
              <el-input
                v-model="paperName"
                type="text"
                size="small"
                placeholder="请输入试卷名称"
                clearable
              />
            </div>
          </div>
          <div class="searchbox" style="margin-top: 10px">
            <!-- <div title="试卷名称" class="searchboxItem ci-full">
              <span class="itemLabel">试卷名称:</span>
              <el-input
                v-model="paperName"
                type="text"
                size="small"
                placeholder="请输入试卷名称"
                clearable
              />
            </div> -->
            <div title="课件名称" class="searchboxItem ci-full">
              <span class="itemLabel">课件名称:</span>
              <el-input
                v-model="catalogName"
                type="text"
                size="small"
                placeholder="请输入课件名称"
                clearable
              />
            </div>
            <div title="考试类型" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 80px">考试类型:</span>
              <el-select
                v-model="paperUserType"
                placeholder="请选择"
                clearable
                size="small"
              >
                <el-option
                  v-for="item in paperusetype"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
             <div title="是否及格" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 80px">是否及格:</span>
              <el-select
                v-model="paperIsPass"
                placeholder="请选择"
                clearable
                size="small"
              >
                <el-option
                  v-for="item in PassNot"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
            <el-button type="primary" class="bgc-bv" round @click="doExport()"
              >导出</el-button
            >
          </div>
          <!-- <div class="searchbox" style="margin-top: 10px">
           
          </div> -->
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="60"
                fixed
                :index="indexMethod"
              />
              <el-table-column
                label="姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
                width="80"
                fixed
              />
              <el-table-column
                label="身份证号"
                align="left"
                show-overflow-tooltip
                prop="idcard"
                fixed
                width="160"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                fixed
                width="160"

              />
              <el-table-column
                label="课程名称"
                align="left"
                show-overflow-tooltip
                prop="courseName"
                min-width="160"
              />
              <el-table-column
                label="课件名称"
                align="left"
                show-overflow-tooltip
                prop="catalogName"
                min-width="120"
              >
                <template slot-scope="scope">{{
                  scope.row.catalogName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="试卷名称"
                align="left"
                show-overflow-tooltip
                prop="paperName"
                min-width="160"
              />
              <el-table-column
                label="考试类型"
                align="center"
                show-overflow-tooltip
                prop="paperUserType"
                min-width="80"
              >
                <template slot-scope="scope">{{
                  scope.row.paperUserType || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="考试开始时间"
                class="cell2"
                align="center"
                show-overflow-tooltip
                prop="joinTime"
                min-width="160"
              >
                  <template slot-scope="scope">
                      {{scope.row.joinTime || "--" }}
                  </template>
              </el-table-column>
              <el-table-column
                label="考试结束时间"
                class="cell2"
                align="center"
                show-overflow-tooltip
                prop="submitTime"
                min-width="160"
              >
                  <template slot-scope="scope">
                      {{scope.row.submitTime || "--" }}
                  </template>
              </el-table-column>
              <el-table-column
                label="考试用时"
                class="cell2"
                align="center"
                show-overflow-tooltip
                prop="examUseTime"
                min-width="160"
              >
                <template slot-scope="scope">
                    {{scope.row.examUseTime || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="成绩"
                align="center"
                show-overflow-tooltip
                prop="paperScore"
                min-width="80"
              />
              <el-table-column
                label="是否及格"
                align="center"
                min-width="70"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.paperIsComplete ? "是" : "否"
                }}</template>
              </el-table-column>
              <el-table-column
                label="正确率"
                align="center"
                min-width="80"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.correctRate || "0%"
                }}</template>
              </el-table-column>
              <el-table-column label="操作" align="center" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handledetail(scope.row)"
                    >答题详情</el-button
                  >
                </div>
              </el-table-column>
              <Empty3 slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty3 from "@/components/Empty3.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { Message } from "element-ui";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "operate/Examination",
  components: {
    Empty3,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      dialogVisible: false,
      catalogName: "",
      projectName: "",
      idCard: "",
      paperName: "",
      userName: "",
      compId: "",
      CompanyList: [],
      paperUserType: "",
      paperusetype: [],
      PassNot: [
        {
          value: "",
          label: "全部",
        },
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      paperIsPass: "",
      userId: "",
      seaUserName: "",
      seaUserIdcard: "",
      seaUserMobile: "",
      userList: [{}],
      projectId: "",
      seaProjectCode: "",
      seaProjectName: "",
      projectList: [{}],
    };
  },
  doNotInit: true,
  created() {
    this.getTableHeight();
    this.superUserSelect();
    this.superPorjectSelect();
    this.getpaperusertypeList();
  },
 computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  watch: {
    projectId: function () {
      this.superUserSelect();
    },
    userId: function () {
      this.superPorjectSelect();
    },
  },
  methods: {
    superUserSelect(e) {
      this.$post(
        "/biz/user/companyUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.seaUserName,
          idcard: this.seaUserIdcard,
          mobile: this.seaUserMobile,
          projectId: this.projectId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    superPorjectSelect(e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          projectName: this.seaProjectName,
          projectCode: this.seaProjectCode,
          userId: this.userId,
        },
        3000,
        false
      ).then((res) => {
        this.projectList = res.data.list;
        // if (res.data.list.length === 0) {
        //   this.projectList = [{}];
        // } else {
        //   this.projectList = res.data.list;
        // }
      });
    },
    clearProjectSearchModel(e) {
      if (e) {
        this.seaProjectName = "";
        this.seaProjectCode = "";
        this.superPorjectSelect();
      }
    },
    clearUserSearchModel(e) {
      if (e) {
        this.seaUserName = "";
        this.seaUserIdcard = "";
        this.seaUserMobile = "";
        this.superUserSelect();
      }
    },
    cancleForm() {
      this.paperIsPass = "";
      this.userId = "";
      this.projectId = "";
      this.projectName = "";
      this.paperName = "";
      this.catalogName = "";
      this.paperUserType = "";
      this.paperIsPass = "";
      this.getData();
    },
    // 导出
    doExport() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      ).then(() => {
        let params = {
          catalogName: this.catalogName || "",
          projectName: this.projectName || "",
          paperName: this.paperName || "",
          userId: this.userId || "",
          projectId: this.projectId || "",
          paperIsComplete: this.paperIsPass,
          paperUserType: this.paperUserType || "",
        };
        this.$post("/biz/paperUser/statisticsPaperListPrint", params).then(
          (res) => {
             if (res.status == "0") {
          let list = res.data;
          this.editPopupDialogVisible = false;
          if (!this.downloadItems.includes(list.taskId)) {
            this.$store.dispatch("pushDownloadItems", list.taskId);
          } else {
            this.$message.warning(
              "[" + list.fileName + "]已经申请下载,请耐心等待"
            );
          }
        } else {
          this.$message.error(res.message);
        }
          }
        );
      });
    },
    getpaperusertypeList() {
      const studylist = this.$setDictionary("PAPERUSERTYPEQUERY", "list");
      const list = [];
      for (const key in studylist) {
        list.push({
          value: key,
          label: studylist[key],
        });
      }
      this.paperusetype = list;
    },
    getData(pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      let par = {
        catalogName: this.catalogName || "",
        projectName: this.projectName || "",
        paperName: this.paperName || "",
        userId: this.userId || "",
        projectId: this.projectId || "",
        paperIsComplete: this.paperIsPass,
        paperUserType: this.paperUserType || "",
      };
      params = {
        ...params,
        ...par,
      };
      this.doFetch({
        url: "/biz/paperUser/statisticsPaperList",
        params,
        pageNum,
      });
    },
    search() {
      this.dialogVisible = !this.dialogVisible;
      this.getTableHeight();
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 2) * 16;
      if (opDom) {
        // tHeight -= 34 + 40 + 40 + 0.675 * 16 + 1;
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    handledetail(row) {
      sessionStorage.setItem("rowJson", JSON.stringify(row));
      this.$router.push({
        path: "/web/operate/ExaminationDetails",
        query: {
          stu: "examination",
        },
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.operationControlExamination {
  > div {
    justify-content: space-between;
  }
  .el-form {
    display: flex;
    .el-form-item {
      margin-bottom: 0;
    }
    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
  .searchboxs {
    justify-content: flex-start;
  }
}
</style>